<!--
  PACKAGE_NAME : src/pages/cc/cti/ibg-category
  FILE_NAME : form.vue
  AUTHOR : hmlee
  DATE : 2024-07-09
  DESCRIPTION : 우측 그룹정보 폼 컴포넌트
-->
<template>
  <div class="layout-cut-right tree-box02 fr">
    <template v-if="category.formData && Object.keys(category.formData).length > 0">
      <table v-if="category.formData.depth < categoryLevel" class="table_form line-bin">
        <caption>
          <strong>{{ $_msgContents('COMPONENTS.GROUP', {defaultValue: '그룹'}) }}</strong>
        </caption>
        <colgroup>
          <col style="width:120px;">
          <col style="width:auto;">
        </colgroup>
        <thead class="sub_title_txt inner-header">
        <tr>
          <td colspan="2"><h2>{{ $_msgContents('CC.WORD.GROUP_INFO', {defaultValue: '그룹정보'}) }}</h2></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">
            <label for="label2">
              {{ category.formData.depth }}
              {{
                $_msgContents('CC.WORD.TH_GROUP_NAME', {defaultValue: '차 그룹명'})
              }}
            </label>
          </th>
          <td>
            <DxTextBox
              :placeholder="$_msgContents('CC.MESSAGE.INPUT_GROUP_NAME', { defaultValue: '그룹명을 입력해주세요.' })"
              v-model="category.formData.ctgNm"
              :max-length="category.limitLength.ctgNm"
              :styling-mode="config.stylingMode"
              :width="300"
              class="mar_ri10"
            >
              <DxValidator validation-group="validationGroupForm">
                <DxAsyncRule
                  :reevaluate="false"
                  :validation-callback="asyncValidation"
                  :message="$_msgContents('CC.MESSAGE.DUPLICATE_NAME', { defaultValue: '이미 등록되어 있는 명칭입니다.'})"
                />
                <DxRequiredRule
                  :message="$_msgContents('CC.MESSAGE.REQUIRED_GROUP_NAME', { defaultValue: '그룹명은 필수입니다.'})"
                />
              </DxValidator>
            </DxTextBox>
            {{
              category.formData.ctgNm !== null ? category.formData.ctgNm.length : 0
            }}/{{ category.limitLength.ctgNm }}자
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label3">
              {{ $_msgContents('COMPONENTS.USE_STATUS', {defaultValue: '사용여부'}) }}
            </label>
          </th>
          <td>
            <DxSwitch @value-changed="onChangedViewFl($event)" :value="getViewFl"/>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label3">
              {{ $_msgContents('CC.WORD.CHILD_GROUP', {defaultValue: '하위 그룹'}) }}
            </label>
          </th>
          <td>
            <template v-if="category.formData.depth === 1 && categoryLevel === 3">
              <DxButton
                :text="$_msgContents('CC.WORD.REGISTER_2RD_GROUP', { defaultValue: '2차 그룹 등록' })"
                class="btn_XS white outlined add2"
                :height="30"
                @click="onAddSubGroup"
              />
            </template>
            <template v-else>
              <DxButton
                :text="$_msgContents('CC.WORD.ADD_IBG', { defaultValue: '인입그룹 추가'})"
                class="btn_XS white outlined add2"
                :height="30"
                @click="onAddGroup"
              />
            </template>
          </td>
        </tr>
        </tbody>

      </table>

      <table v-else class="table_form line-bin">
        <caption>
          <strong>{{ $_msgContents('COMPONENTS.GROUP', {defaultValue: '그룹'}) }}</strong>
        </caption>
        <colgroup>
          <col style="width:120px;">
          <col style="width:250px">
          <col style="width:130px;">
          <col style="width:auto;">
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="4">
            <h2>
              {{ $_msgContents('CC.WORD.GROUP_INFO', {defaultValue: '그룹정보'}) }}
            </h2>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">
            <label for="label21">
              {{ $_msgContents('CC.WORD.IBG', {defaultValue: '인입그룹'}) }}
              {{ $_msgContents('CC.WORD.GROUP_NAME', {defaultValue: '그룹명'}) }}
              <br/>
              ({{ ($_msgContents('CC.WORD.ERS_NAME', {defaultValue: 'ERS 이름'})) }})
            </label>
          </th>
          <td id="label21">
            {{ category.formData.ibg.ibgNm }}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label31">
              {{ $_msgContents('CC.WORD.CTI_NAME', {defaultValue: 'CTI 이름'}) }}
            </label>
          </th>
          <td id="label31">
            {{ category.formData.ibg.ibg }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>

    <template v-else>
      <div class="noformdata">{{ $_msgContents('CC.MESSAGE.SELECT_GROUP', {defaultValue: '그룹을 선택하세요.'}) }}</div>
    </template>

    <!-- 하단 버튼 -->
    <section v-if="category.formData && category.formData.depth < categoryLevel" class="bottom-btn-wrap">
      <DxButton
        :text="$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장'})"
        class="btn_XS default filled txt_S medium"
        :width="120"
        :height="40"
        validation-group="validationGroupForm"
        :use-submit-behavior="true"
        @click="onSaveFormData"
      />
      <DxButton
        :text="$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소'})"
        class="btn_XS white filled txt_S medium"
        :width="120"
        :height="40"
        @click="onCancel"
      />
    </section>
  </div>

</template>

<script>
import {DxValidator, DxRequiredRule, DxAsyncRule} from 'devextreme-vue/validator';

import {DxTextBox} from 'devextreme-vue/text-box';
import {DxButton} from 'devextreme-vue/button';

import DxSwitch from "devextreme-vue/switch";
import {isSuccess} from "@/plugins/common-lib";

export default {
  components: {
    DxValidator,
    DxRequiredRule,
    DxAsyncRule,

    DxTextBox,
    DxButton,
    DxSwitch,
  },
  props: {
    clickedRowData: {
      type: Object,
      default: () => ({}),
    },
    categoryLevel: {
      type: Number,
      default: 3,
    },
    treeList: Array,
  },
  watch: {
    clickedRowData(newVal) {
      this.category.originData = this.clickedRowData;
      const formData = {...newVal};  //부모 데이터 변경 방지
      this.category.formData = formData;
    }
  },
  data() {
    return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        isViewFl: true,
      },
      category: {
        level: null,
        formData: this.clickedRowData,
        originData: null,
        limitLength: {
          ctgNm: 20,
        }
      },
    }
  },
  computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.category.formData.viewFl === 'Y';
    },
    /** @description: 2depth 최대 순서값 조회 */
    getMaxOrdBy2Depth() {
      return this.treeList.filter(d => d.depth === 2).length;
    },
    /** @description: 3depth 최대 순서값 조회 */
    getMaxOrdBy3Depth() {
      return this.treeList.filter(d => d.depth === 3).length;
    },
  },
  methods: {
    /** @description: 2차 그룹 추가 */
    onAddSubGroup() {
      this.$parent.onOpenModal(
        'ModalIbgCategory',
        {
          title: this.$_msgContents('CC.WORD.REGISTER_2RD_GROUP', {defaultValue: '2차 그룹 등록'}),
          buttons: {
            save: {text: this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'})},
            cancel: {text: this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'})},
          },
          width: '500',
          height: '250',
        },
        {
          depth: this.category.formData.depth + 1,
          parentId: this.category.formData.id,
          ibgCtgOrd: this.getMaxOrdBy2Depth,
        }
      );
    },
    /** @description: 그룹 추가 */
    onAddGroup() {
      const child = this.$_msgContents('CC.WORD.CHILD', {defaultValue: '하위'});
      const addIbg = this.$_msgContents('CC.WORD.ADD_IBG', {defaultValue: '인입그룹 추가'});
      this.$parent.onOpenModal(
        'ModalIbg',
        {
          title: `${child} ${addIbg}`,
          buttons: {
            save: {text: this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'})},
            cancel: {text: this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'})},
          },
          width: '800',
          height: '650',
        },
        {
          depth: this.category.formData.depth + 1,
          parentId: this.category.formData.id,
          ibgCtgOrd: this.getMaxOrdBy3Depth,
        }
      );
    },
    /** @description: 사용여부 이벤트 */
    onChangedViewFl(e) {
      if (e.value) this.category.formData.viewFl = 'Y';
      else this.category.formData.viewFl = 'N';
    },
    /** @description : 그룹명 중복 체크 화면 처리 */
    async asyncValidation(e) {
      const checkInput = document.querySelector('div.dx-texteditor-input-container');
      const style = document.createElement('style');
      if(e.value === this.category.originData.ctgNm) { //기존 그룹명과 이름이 같으면
        style.innerHTML = ":after{ display: none; }";
        checkInput.appendChild(style);
        return true;
      }else { //기존 그룹명과 이름이 다르면 그룹명 중복 체크
        style.innerHTML = ":after{ display: block; }";
        checkInput.appendChild(style);
        return await this.checkDuplicateCtgNm(e.value);
      }
    },
    /** @description : 그룹명 중복 체크 API 호출 */
    async checkDuplicateCtgNm(ctgNm) {
      const params = {ctgNm: ctgNm, parentId: this.category.formData.parentId};
      const payload = {
        actionname: 'CC_IBG_CATEGORY_LIST',
        data: params,
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res) && res.data.data.length > 0) { //그룹명 중복 데이터가 존재하면
        return false;
      }else {
        return true;
      }
    },
    /** @description : 데이터 저장시 메서드 */
    async onSaveFormData(e) {
      //validation 체크
      if (!e.validationGroup.validate().isValid) {
        return false;
      }

      this.$parent.updateData([this.category.formData]);
    },
    /** @description : 취소 버튼 클릭시 메서드 */
    onCancel() {
      this.$parent.onCancelForm(); //페이지 초기화
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style scoped>
.bottom-btn-wrap {
  width: calc(100% - 600px);
  position: fixed;
  bottom: 20px;
}
</style>s